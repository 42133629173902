<template>
  <app-spinner v-if="isLoading" />
  <section v-else class="department">
    <section class="hero-small" :style="`background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(${department.content.presentationImage.filename})`">
      <div>
        <h1 class="h2">Votre projet de construction en {{ department.content.name }}</h1>
        <configurator-search />
      </div>
    </section>
    <div class="grid-container">
      <section class="map">
        <div class="grid-x section-content map-content">
          <div class="cell medium-6 small-12 map-text">
            <h1>
              {{ department.content.introductionTitle }}
            </h1>
            <p>
              {{ department.content.introductionText }}
            </p>
            <router-link :to="{ name: 'departement-agences', params: { slugDepartment: department.slug } }">
              <app-button>Voir les agences</app-button>
            </router-link>
          </div>
          <div class="cell medium-6 small-12 map-illu">
            <div>
              <img
                :src="`${department.content.introductionImage.filename}`"
                :alt="`${department.content.introductionImage.alt}`"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="gallery" v-if="department.content.styleImages.length">
        <div class="section-content gallery-content">
          <h2>{{ department.content.styleTitle }}</h2>
          <div class="grid-x grid-margin-y gallery-row">
            <div class="cell medium-4 small-12 gallery-image" v-for="styleImage in department.content.styleImages" :key="styleImage.id">
              <img :src="styleImage.filename" :alt="styleImage.alt" />
            </div>
          </div>
          <div class="small-12 frame-estate">
            <div class="blur"></div>
            <div class="content">
              <h2>Besoin d’un chiffrage rapidement ?</h2>
              <h3 class="h6">Rien de plus facile avec notre incroyable configurateur.</h3>
              <ul>
                <li>Trouver votre bonheur parmi des centaines d'annonces terrains.</li>
                <li>Sélectionnez la maison de vos rêves et tous ses équipements.</li>
                <li>Obtenez votre chiffrage complet.</li>
              </ul>
              <router-link :to="{ name: 'home', hash: '#hero' }" class="link-button">
                <app-button hasArrow>Commencer</app-button>
              </router-link>
              <router-link :to="{ name: 'configurator' }">En savoir plus</router-link>
            </div>
          </div>
        </div>
      </section>

      <section class="informations" v-if="department.content.seoText && department.content.seoText.content[0].content">
        <div class="section-content informations-content">
          <card-type hasBigText>
            <template v-slot:bigText>
              <span v-html="transformToRichText(department.content.seoText)"/>
            </template>
          </card-type>
        </div>
      </section>

      <section v-if="department.content.number !== '86'" class="related-cities">
        <div class="grid-container">
          <div class="grid-x grid-margin-x">
            <div class="cell">
              <h2>Ces communes en {{ department.content.name }} pourraient vous intéresser :</h2>
              <ul>
                <li v-for="city in cities" :key="city.id">
                  <router-link :to="`/${city.content.slug}`">{{ city.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="frames">
        <div class="section-content frames-content">
          <frame-cards />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import FrameCards from '@/components/general/FrameCards.vue';
import ConfiguratorSearch from '@/components/general/ConfiguratorSearch.vue';
import Storyblok from '@/services/storyblok';
import CardType from '@/components/general/CardType.vue';

export default {
  name: 'department',
  components: {
    FrameCards,
    ConfiguratorSearch,
    CardType,
  },
  data() {
    return {
      isCityValidated: null,
      selectedPostalCode: null,
      postalCodeInseeNumber: null,
      isModalOpen: false,
      cities: [],
      isLoading: true,
      department: null,
      richText: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getDepartment();
    await this.getCities();
    this.isLoading = false;
  },
  methods: {
    transformToRichText(richText) {
      const result = Storyblok.richTextResolver.render(richText);
      return result.replace(/<p><\/p>/g, '<br/>');
    },
    async getDepartment() {
      try {
        const dptName = this.$route.path.split('/')[2].slice(0, -3);
        const response = await Storyblok.get(`cdn/stories/departments/${dptName}`);
        this.department = response.data.story;
      } catch (err) {
        throw err;
      }
    },
    async getCities() {
      try {
        const dept = this.$route.path.slice(-2);
        const response = await Storyblok.get('cdn/stories/', {
          version: 'published',
          starts_with: 'cities/',
          filter_query: {
            inseeNumber: {
              like: `${dept}*`,
            },
          },
        });
        this.cities = response.data.stories;
      } catch (err) {
        throw err;
      }
    },
    submitCity() {
      if (this.postalCodeInseeNumber && this.postalCodeInseeNumber.length > 4) {
        this.isCityValidated = true;
      }
    },
    scrollToTop() {
      const top = document.querySelector('.home');
      top.scrollIntoView({ behavior: 'smooth' });
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.postalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.postalCodeInseeNumber = value.name;
    },
  },
};
</script>

<style lang="sass">
.department
  background: $medium-bg
  overflow: hidden
  .hero-small
    @include hero-small
    h1
      margin: 0 auto 4rem auto
    @media (max-width: 768px)
      min-height: 700px
      h2
        @include heading-md
        margin: 0 auto 2rem auto
  .configurator-search
    min-width: 300px
    width: 100%
    margin: 57px auto auto auto
    @media (max-width: 1024px)
      width: 100%
  .card-find-house
    @include card-find-house
  .grid-container
    padding: 0
    margin: 0 auto
    max-width: 100%
  section:not(.hero-small)
    padding: 100px 0 80px 0
    display: flex
    .section-content
      max-width: 1200px
      margin: auto
    @media (max-width: 768px)
      padding: 75px 20px 25px 20px
  .map
    .map-text
      h1
        margin-bottom: 32px
      p
        white-space: pre-wrap
      .app-button
        margin-top: 32px
      a
        text-decoration: none
    .map-illu
      display: flex
      &>div
        margin-left: auto
        &>img
          display: flex
          margin-left: auto
          max-width: 80%
    @media (max-width: 768px)
      .map-content
        flex-direction: column-reverse
        .map-text
          h1
            text-align: center
          p
            text-align: center
          .app-button
            width: 100%
        .map-illu
          padding: 0 20px
          box-sizing: border-box
          img
            width: 100%
  .gallery
    background: $white
    .gallery-content
      width: 1200px
      &>h2
        text-align: center
        max-width: 1016px
        margin: auto
        margin-bottom: 48px
      .gallery-row
        width: 100%
        margin-bottom: 32px
        .gallery-image
          display: flex
          img
            width: 90%
            margin: auto
            border-radius: 8px
    @media (max-width: 768px)
      .gallery-content
        .gallery-row
          margin-bottom: 0
          .gallery-image
            img
              margin-bottom: 12px
    .frame-estate
      background: url(~@/assets/img/pages/computer-people.jpg) no-repeat center center
      background-size: cover
      position: relative
      display: flex
      justify-content: flex-end
      border-radius: 8px
      overflow: hidden
      margin: 80px 0 40px 0
      .blur
        position: absolute
        width: 40%
        height: 100%
        top: 0
        right: 0
        z-index: 1
        @include black-filter
      .content
        width: 40%
        position: relative
        z-index: 2
        padding: 60px 40px 60px 60px
        box-sizing: border-box
        color: $white
        display: flex
        flex-direction: column
        h2
          color: $white
          margin-bottom: 38px
        .h6
          color: $white
          margin-bottom: 28px
          font-weight: 400
        ul
          margin-bottom: 50px
          padding-left: 20px
          li
            list-style-type: disc
            line-height: 24px
        .link-button
          text-decoration: none
          width: 100%
          .app-button
            width: 100%
        a
          color: $white
          margin: 30px auto 0 auto
      @media (max-width: 768px)
        margin-bottom: 25px
        .blur
          width: 100%
        .content
          width: 100%
          padding: 80px 16px 40px 16px
          li
            font-size: 15px
          .app-button
            width: 165px
            margin: 0 auto
  .informations
    .informations-content
      width: 1200px
      @media (max-width: 768px)
        padding-bottom: 50px
      h2
        text-align: center
        max-width: 770px
        margin: auto
        margin-bottom: 48px
      a
        color: $primary
        text-decoration: none
        font-size: 16px
      .small
        font-size: 12px
        color: #4C5367
        font-weight: 500
        line-height: 16px
  .frames
    background: $white
    .frames-content
      width: 100%
      .frame-cards
        margin-top: 0
  .related-cities
    @include related-cities
</style>
